<!--
  PACKAGE_NAME : src\pages\esp\auth\search-data-grid.vue
  FILE_NAME : search-data-grid
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 보고서 검색 대상 권한 그리드
-->
<template>
  <div class="max-w-lg mr-2.5">
    <div style="height: 40px">
      <div style="float: right; top: 8px">
        선택 <span class="col_blue2">{{ selectedKeys.length }}</span
        >개 / 전체 <span class="col_blue2">{{ totalCount }}</span
        >개
      </div>
    </div>
    <DxDataGrid
      v-if="dataGrid.dataField"
      :visible="dataGrid.visible"
      :id="dataGrid.dataField"
      :ref="dataGrid.dataField"
      :data-source="dataSource"
      :disabled="disabled"
      :show-borders="dataGrid.showBorders"
      :show-column-headers="dataGrid.showColumnHeaders"
      :show-column-lines="dataGrid.showColumnLines"
      :show-row-lines="dataGrid.showRowLines"
      :row-alternation-enabled="dataGrid.rowAlternationEnabled"
      :allow-column-reordering="dataGrid.allowColumnReordering"
      :width="dataGrid.width"
      :height="dataGrid.height"
      :selected-row-keys="target[dataGrid.dataField]"
      :key-expr="dataGrid.keyExpr"
      :no-data-text="noDataText"
      @selection-changed="onSelectedRow"
      @row-prepared="onRowPrepared"
    >
      <template v-for="(column, index2) in dataGrid.columns">
        <DxColumn
          v-if="column.dataField"
          :key="index2"
          :caption="column.caption"
          :data-field="column.dataField"
          :group-cell-template="column.groupCellTemplate || null"
          :width="column.width"
          :alignment="column.alignment"
          :visible="column.visible"
        />
      </template>
      <DxFilterRow :visible="true" />
      <DxSelectionGrid
        v-if="dataGrid.selection"
        :allow-select-all="dataGrid.selection.allowSelectAll ? dataGrid.selection.allowSelectAll : true"
        :select-all-mode="dataGrid.selection.selectAllMode ? dataGrid.selection.selectAllMode : 'allPages'"
        :show-check-boxes-mode="dataGrid.selection.showCheckBoxesMode ? dataGrid.selection.showCheckBoxesMode : 'always'"
        :mode="dataGrid.selection.mode ? dataGrid.selection.mode : 'multiple'"
      />
      <DxScrolling v-if="dataGrid.scrolling" :mode="dataGrid.scrolling.mode ? dataGrid.scrolling.mode : 'virtual'" />
      <DxPaging :enabled="false" />
    </DxDataGrid>
    <div v-if="['site', 'tenant'].includes(dataGrid.dataField) && dataGrid.visible" :key="dataGrid.dataField" class="arrow-box">
      <span class="mdi mdi-chevron-right mdi-24px"></span>
    </div>
    <div
      v-if="['ibg', 'skl', 'agtteam'].includes(dataGrid.dataField) && dataGrid.visible"
      :key="dataGrid.dataField"
      class="empty-box"
    ></div>
    <div v-if="['ivrdnis', 'vdn'].includes(dataGrid.dataField) && dataGrid.visible" :key="dataGrid.dataField" class="empty-box"></div>
  </div>
</template>

<script>
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxColumn, DxDataGrid, DxFilterRow, DxPaging, DxScrolling, DxSelection as DxSelectionGrid } from 'devextreme-vue/data-grid';

  export default {
    components: {
      DxFilterRow,
      DxColumn,
      DxDataGrid,
      DxSelectionGrid,
      DxScrolling,
      DxPaging,
    },
    props: {
      totalCount: {
        type: Number,
        default: 0,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      dataGrid: {
        type: Object,
        default: undefined,
      },
      target: {
        type: Object,
        default: undefined,
      },
      dataSource: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      /** @description: 검색대상노출 데이터가 없을 경우 출력 */
      noDataText() {
        return this.$_msgContents('CMN_NO_DATA') ? this.$_msgContents('CMN_NO_DATA') : '데이터가 없습니다.';
      },
    },
    data() {
      return {
        selectedKeys: [],
      };
    },
    methods: {
      onSelectedRow(e) {
        this.selectedKeys = e.selectedRowKeys;
        this.$emit('childEvent', this.target, this.dataGrid.dataField, e.selectedRowKeys);
      },
      /** @description: 그리드 row prepared 이벤트 */
      onRowPrepared(e) {
        // 그룹 row일 경우 체크박스 생성
        if (e.rowType === 'group') {
          const groupCheckBoxCell = e.cells[0].cellElement;
          groupCheckBoxCell.style.textAlign = 'center';
          const groupData = e.data;
          const dataGridId = e.element.id;
          const checkBox = new DxCheckBox({
            propsData: {
              value: false,
              alignment: 'center',
              onValueChanged: e => {
                this.$emit('RowPreparedEvent', e, dataGridId, groupData);
              },
            },
          });
          checkBox.$mount();
          groupCheckBoxCell.append(checkBox.$el);
        }
      },
    },
    mounted() {
      if (this.target[this.dataGrid.dataField]) {
        this.selectedKeys = this.target[this.dataGrid.dataField];
      }
    },
  };
</script>
